import React, { useState } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import SchoolsList from "../../CmnCompnts/SchoolsList";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useContext } from "react";
import { UserContext } from "../../Context/Reducer";
import { useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import "../../CSSFiles/common.css"
import { getAllQuestionBankSets, getQuestionBankSetById } from "../../../Services/api";

const QuizReportNew = (props) => {
    const { state, dispatch } = useContext(UserContext);
    const [isDownloadCSVClicked, setIsDownloadCSVClicked] = useState(false)
    const [minMarksAndLevel, setMinMarksAndLevel] = useState()
    const [reportData, setReportData] = useState([])

    useEffect(() => {
        // console.log("reportData", reportData);
    }, [minMarksAndLevel])

    // const getQbSet = async (id) => {
    //     let questionBankSets = await getQuestionBankSetById(id)
    //     setMinMarksAndLevel({
    //         level: Object.keys(questionBankSets.proficiencyLevel[0]),
    //         minMarks: questionBankSets.resultMinMarks
    //     })
    //     // console.log("questionBankSets", questionBankSets, "Saddasdasd", questionBankSets.resultMinMarks);
    //     return questionBankSets;
    // }

    const getQbSet = async (id) => {
        let questionBankSets = await getQuestionBankSetById(id);
        if (questionBankSets.proficiencyLevel && questionBankSets.proficiencyLevel.length > 0) {
            setMinMarksAndLevel({
                level: Object.keys(questionBankSets.proficiencyLevel[0]),
                minMarks: questionBankSets.resultMinMarks
            });
        }

        return questionBankSets;
    };


    const [slctSchool, setSlctSchool] = useState();
    const [userData, setUserData] = useState([])
    const [quizData, setQuizData] = useState([])
    const [csvData, setCsvData] = useState();

    const exportPDF = (userData) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Quiz Report";
        const headers = [["ID", "NAME", "Quiz Result"]];
        const headers2 = [["ID2", "NAME2", "New"]];

        console.log("lllllllllfffff", userData);
        let temp = []
        // let content2 = {
        //     startY: 50,
        //     head: headers2,
        //     body: temp
        // };
        let temp2 = []
        const data = userData.map((elt, i) => {
            temp2 = [];
            console.log("mmmmm", elt.quizUserDetails);
            elt.quizUserDetails.map((user) => {
                if (user.quizResultSummary.length > 0) {
                    user.quizResultSummary.map((dt3, i3) => {
                        let count = 0;
                        let arrayCount = 0
                        let correctAnswers = dt3.attemptedQuestions.map((a, b) => {
                            if (a.isAnswerCorrect === "YES") {
                                count += 1
                            }
                            if (Array.isArray(a.userAns) && a.userAns.some(ans => typeof ans === "string")) {
                                arrayCount += 1;
                                console.log("arrayCount", arrayCount);
                            }
                        })
                        temp2.push([(i3 + 1), dt3.totalQuestions, arrayCount, count, dt3.score, dt3.status])
                    })
                }
                temp.push([
                    (i + 1),
                    user.userName,
                    user.quizResultSummary.length > 0 ? temp2 : "No Data Found"
                ])
            })
        });

        // const data2 = userData.map((dt, i) => {
        //     return dt.quizUserDetails.map((dt2, i2) => {
        //         return dt2.quizResultSummary.map((dt3, i3) => {
        //             let count = 0;
        //             let correctAnswers = dt3.attemptedQuestions.map((a, b) => {
        //                 if (a.isAnswerCorrect === "YES") {
        //                     count += 1
        //                 }
        //             })
        //             // temp2.push([(i3 + 1), dt3.totalQuestions, dt3.attemptedQuestions.length, count, dt3.score, dt3.status])
        //             return [(i3 + 1), dt3.totalQuestions, dt3.attemptedQuestions.length, count, dt3.score, dt3.status]
        //         })
        //     })
        // })
        // console.log("===============", temp2);
        // const data3 = temp2

        let content = {
            startY: 50,
            head: headers,
            body: temp,
            bodyStyles: {
                minCellHeight: 70,
            },
            styles: {
                cellPadding: 2,
            },
            columnStyles: {
                0: { cellWidth: 30 },
                1: { cellWidth: 100 },
            },
            didDrawCell: (data) => {
                if (data.column.dataKey === 2 && data.cell.section === 'body' && Array.isArray(data.cell.raw)) {
                    // doc.autoTable({
                    //     startY: data.cell.y + 2,
                    //     head: headers2,
                    //     body: temp
                    // });
                    doc.autoTable({
                        head: [["Attempt", "Total Questions", "Questions Attended", "Correct Answers", "Score", "Status"]],
                        // body: [
                        //     ["1", "2333333333333333333333", "3", "4"],
                        //     ["1", "2", "3", "4"],
                        //     ["1", "2", "3", "4"],
                        //     ["1", "2", "3", "4"]
                        // ],
                        // body: temp2,
                        body: data.cell.raw,
                        bodyStyles: {
                            minCellWidth: 35,
                            minCellHeight: data.cell.height + 15
                        },
                        startY: data.cell.y + 2,
                        margin: { left: data.cell.x + data.cell.padding('left') },
                        tableWidth: 'wrap',
                        theme: 'grid',
                        styles: {
                            fontSize: 10,
                            cellPadding: 2,
                        }
                    });
                }
            }
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        // doc.table(content);
        doc.save("report.pdf")
    }

    // const Csv = (e) => {
    //     e.preventDefault();
    //     setDownloadCsv(true)
    // }

    // const getAllQuizReport = (_lmAccountId) => {
    //     // console.log("ssssssssss", _lmAccountId);
    //     axios.post("/quiz/getAllReport", { id: _lmAccountId })
    //         .then(async (res) => {
    //             // console.log("Report data:", res.data);
    //             setUserData(res.data)
    //             let tempArr = []
    //             res.data.map(async (dt, i) => {
    //                 let tempSetId = await getQbSet(dt.questionBankSetId)
    //                 tempArr.push({
    //                     ...dt, level: Object.keys(tempSetId.proficiencyLevel[0]).sort().reverse(),
    //                     minMarks: tempSetId.resultMinMarks
    //                 })
    //                 // console.log("mmmmmmmmmmmmmmmmmmmmmmmmm", tempArr);
    //                 // console.log("nnnnnnnnnnnnnnnnnnnn", res.data);
    //                 setReportData(tempArr)
    //             })
    //         })
    //         .catch((err) => {
    //             console.log("Error", err);
    //         })
    // }

    const getAllQuizReport = (_lmAccountId) => {
        // Check if report data already exists
        if (reportData.length > 0) {
            return; // Skip API call if data is already loaded
        }

        axios.post("/quiz/getAllReport", { id: _lmAccountId })
            .then(async (res) => {
                setUserData(res.data);
                let tempArr = [];

                for (const [i, dt] of res.data.entries()) {
                    let tempSetId = await getQbSet(dt.questionBankSetId);

                    if (tempSetId.proficiencyLevel && tempSetId.proficiencyLevel.length > 0) {
                        tempArr.push({
                            ...dt,
                            level: Object.keys(tempSetId.proficiencyLevel[0]).sort().reverse(),
                            minMarks: tempSetId.resultMinMarks
                        });
                    }
                }

                setReportData(tempArr);
            })
            .catch((err) => {
                console.log("Error", err);
            });
    };

    // const downloadCSV = () => {
    //     console.log("report data", reportData)
    //     let temp = []
    //     reportData.map((dt, i) => {
    //         console.log("dt", dt);
    //         dt.quizUserDetails.map((dt2, i2) => {
    //             console.log("dt2", dt2);
    //             dt2.quizResultSummary.map((dt3, i3) => {
    //                 console.log("dt3", dt3);
    //                 let count = 0;
    //                 let arrayCount = 0
    //                 let correctAnswers = dt3.attemptedQuestions.map((a, b) => {
    //                     if (a.isAnswerCorrect === "YES") {
    //                         count += 1
    //                     }
    //                     if (Array.isArray(a.userAns) && a.userAns.some(ans => typeof ans === "string")) {
    //                         arrayCount += 1;
    //                         console.log("arrayCount", arrayCount);
    //                     }
    //                 })
    //                 console.log("dt3", dt3)
    //                 temp.push({
    //                     "Quiz Name": dt.quizName,
    //                     "Quiz Start Date": dt.startDate,
    //                     "Quiz End Date": dt.endDate,
    //                     "UserName": dt2.userName,
    //                     "Attempts": dt3.attempt,
    //                     "Total Questions": dt3.totalQuestions,
    //                     "Questions Attended": arrayCount,
    //                     "Correct Answers": count,
    //                     "Score": dt3.score,
    //                     "Status": dt3.status,
    //                 })
    //             })
    //         })
    //     })
    //     setCsvData(temp)
    // }

    const downloadCSV = () => {
        console.log("report data", reportData);
        let temp = [];

        reportData.map((dt, i) => {
            console.log("dt", dt);

            if (!dt.quizUserDetails) {
                console.log("This school does not have any students");
                return; // Skip to the next iteration if there are no user details
            }

            if (dt.quizUserDetails.length === 0) {
                console.log("This school does not have any students");
                return; // Skip to the next iteration if there are no students
            }

            dt.quizUserDetails.map((dt2, i2) => {
                console.log("dt2", dt2);

                if (!dt2) {
                    console.log("Students have not attended the quiz");
                    return; // Skip to the next iteration if dt2 is null
                }

                dt2.quizResultSummary.map((dt3, i3) => {
                    console.log("dt3", dt3);

                    let count = 0;
                    let arrayCount = 0;
                    let correctAnswers = dt3.attemptedQuestions.map((a, b) => {
                        if (a.isAnswerCorrect === "YES") {
                            count += 1;
                        }
                        if (Array.isArray(a.userAns) && a.userAns.some(ans => typeof ans === "string")) {
                            arrayCount += 1;
                            console.log("arrayCount", arrayCount);
                        }
                    });

                    console.log("dt3", dt3);
                    temp.push({
                        "Quiz Name": dt.quizName,
                        "Quiz Start Date": dt.startDate,
                        "Quiz End Date": dt.endDate,
                        "UserName": dt2.userName,
                        "Attempts": dt3.attempt,
                        "Total Questions": dt3.totalQuestions,
                        "Questions Attended": arrayCount,
                        "Correct Answers": count,
                        "Score": dt3.score,
                        "Status": dt3.status,
                    });
                });
            });
        });

        setCsvData(temp);
    };


    return <Container>
        <Row>
            <Row>
                <Col className="d-flex">
                    <SchoolsList setSlctSchool={setSlctSchool} />
                    <Col className=" ms-1 d-flex ">
                        <button className='ms-2 mt-2 prim-btn' onClick={(e) => {
                            e.preventDefault();
                            console.log(("dddddddddddrrrrrrrrrrrrrr", slctSchool));
                            getAllQuizReport(slctSchool.id)
                        }}>Select</button>
                    </Col>
                </Col>
                <Col className="mt-1" xs={12}>
                    {
                        reportData.length > 0 ?
                            <div>
                                {
                                    console.log("reportData", reportData)
                                }
                                {
                                    reportData.map((quiz, quizId) => {
                                        return <section style={{ backgroundColor: "white", padding: "15px", marginTop: "20px", border: "1px solid gray" }}>
                                            <div className="mt-2">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h5>
                                                        Quiz Name : <b>{quiz.quizName}</b>
                                                    </h5>
                                                    <div style={{ textAlign: "right" }}>
                                                        <button className="prim-btn me-2" onClick={(e) => {
                                                            e.preventDefault();
                                                            downloadCSV()
                                                            setIsDownloadCSVClicked(true)
                                                        }}>Export CSV</button>
                                                        
                                                        {isDownloadCSVClicked ? <CSVDownload data={csvData} target="_blank" /> : ""}
                                                        <button className="prim-btn" onClick={() => {
                                                            let temp = []
                                                            temp.push(quiz)
                                                            exportPDF(temp)
                                                        }}>Download PDF</button>
                                                    </div>
                                                </div>
                                                <Table className="mt-3" responsive bordered hover id="table" style={{ backgroundColor: "white" }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="tble-equal-padding" style={{ minWidth: "20px" }}>Id</th>
                                                            <th className="tble-equal-padding" style={{ minWidth: "150px" }}>Name</th>
                                                            <th className="tble-equal-padding" style={{ minWidth: "150px" }}>Quiz Result</th>
                                                        </tr>
                                                        {
                                                            quiz.quizUserDetails.map((quizuser, k) => {
                                                                if (quizuser) {
                                                                    return <tr>
                                                                        <td>{k + 1}</td>
                                                                        <td>{quizuser.userName}</td>
                                                                        <td>
                                                                            <thead>
                                                                                <tr style={{ height: "35px", border: "1px solid #b7b7b7" }}>
                                                                                    <th className="tble-equal-padding tbl-right-brdr">Attempt</th>
                                                                                    <th className="tble-equal-padding tbl-right-brdr">Total Questions</th>
                                                                                    <th className="tble-equal-padding tbl-right-brdr">Questions Attended</th>
                                                                                    <th className="tble-equal-padding tbl-right-brdr">Correct Answers</th>
                                                                                    <th className="tble-equal-padding tbl-right-brdr">Score</th>
                                                                                    <th className="tble-equal-padding tbl-right-brdr">Status</th>
                                                                                </tr>
                                                                            </thead>
                                                                            {
                                                                                quizuser.quizResultSummary.length > 0 ? quizuser.quizResultSummary.map((quizResult, z) => {
                                                                                    let count = 0;
                                                                                    let arrayCount = 0;
                                                                                    let correctAnswers = quizResult.attemptedQuestions.map((a, b) => {
                                                                                        if (a.isAnswerCorrect === "YES") {
                                                                                            count += 1
                                                                                        }
                                                                                        if (Array.isArray(a.userAns) && a.userAns.some(ans => typeof ans === "string")) {
                                                                                            arrayCount += 1;
                                                                                            console.log("arrayCount", arrayCount);
                                                                                        }
                                                                                    })

                                                                                    return <tr style={{ height: "35px", border: "1px solid #b7b7b7" }}>
                                                                                        <td className="tble-equal-padding tbl-right-brdr">{z + 1}</td>
                                                                                        <td className="tble-equal-padding tbl-right-brdr">{quizResult.totalQuestions}</td>
                                                                                        <td className="tble-equal-padding tbl-right-brdr">{arrayCount}</td>
                                                                                        <td className="tble-equal-padding tbl-right-brdr">{count}</td>
                                                                                        <td className="tble-equal-padding tbl-right-brdr">{quizResult.score}</td>
                                                                                        <td className={`tble-equal-padding tbl-right-brdr `} style={{ minWidth: "150px" }}>
                                                                                            <span className={`${quizResult.score >= quiz.minMarks ? "passtag" : "failtag"}`}>
                                                                                                {
                                                                                                    quizResult.score >= quiz.minMarks ? quiz.level[0] : quiz.level[1]
                                                                                                }
                                                                                            </span>
                                                                                            {/* <span className={`${quizResult.status === "PASS" ? "passtag" : "failtag"}`}>
                                                                                                {quizResult.status}
                                                                                            </span> */}
                                                                                        </td>
                                                                                    </tr>
                                                                                }) : <div><span style={{ backgroundColor: "red", padding: "0px 5px 0px 5px", color: "white", borderRadius: "50px" }}>Quiz Not Started</span></div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            })
                                                        }
                                                    </thead>
                                                </Table>
                                            </div>
                                        </section>
                                    })
                                }
                            </div> : <h4>No Data Found</h4>
                    }
                </Col>
            </Row>
        </Row>
    </Container >
}


export default QuizReportNew;



// import React, { useState } from "react";
// import { Container, Row, Col, Table, Button } from "react-bootstrap";
// import SchoolsList from "../../CmnCompnts/SchoolsList";
// import jsPDF from "jspdf";
// import 'jspdf-autotable';
// import { useContext } from "react";
// import { UserContext } from "../../Context/Reducer";
// import { useEffect } from "react";
// import { CSVLink, CSVDownload } from "react-csv";
// import axios from "axios";


// const QuizReportNew = (props) => {
//     const { state, dispatch } = useContext(UserContext);
//     const [isDownloadCSVClicked, setIsDownloadCSVClicked] = useState(false)
//     useEffect(() => {
//         console.log("-------------", state);
//     }, state)
//     const [slctSchool, setSlctSchool] = useState();
//     const Students = [
//         { Id: "1", Name: "Chethan m", Total_Questions: "30", Questions_Attended: "24", Number_of_Attempts: "2", Crrct_Ans: "23", Batch_Name: "Batch 1", status: "FAIL" },
//         { Id: "2", Name: "Ikraam s", Total_Questions: "30", Questions_Attended: "30", Number_of_Attempts: "3", Crrct_Ans: "1", Batch_Name: "Batch 1", status: "PASS" },
//         { Id: "3", Name: "Shashank R", Total_Questions: "30", Questions_Attended: "30", Number_of_Attempts: "1", Crrct_Ans: "2", Batch_Name: "Batch 1", status: "PASS" },
//         { Id: "4", Name: "Ravinder k", Total_Questions: "30", Questions_Attended: "5", Number_of_Attempts: "2", Crrct_Ans: "30", Batch_Name: "Batch 1", status: "FAIL" }
//     ]
//     const [test, setTest] = useState(Students)
//     const [reportData, setReportData] = useState([])

//     const exportPDF = () => {
//         const unit = "pt";
//         const size = "A4"; // Use A1, A2, A3 or A4
//         const orientation = "portrait"; // portrait or landscape

//         const marginLeft = 40;
//         const doc = new jsPDF(orientation, unit, size);

//         doc.setFontSize(15);

//         const title = "Quiz Report";
//         const headers = [["ID", "NAME", "TOTAL QUESTIONS", "QUESTIONS ATTENDED", "NUMBER OF ATTEMPTS", "CORRECT AMSWERS", "BATCH NAME", "STATUS"]];

//         const data = test.map(elt => [elt.Id, elt.Name, elt.Total_Questions, elt.Questions_Attended, elt.Number_of_Attempts, elt.Crrct_Ans, elt.Batch_Name, elt.status]);

//         let content = {
//             startY: 50,
//             head: headers,
//             body: data
//         };

//         doc.text(title, marginLeft, 40);
//         doc.autoTable(content);
//         doc.save("report.pdf")
//     }

//     // const Csv = (e) => {
//     //     e.preventDefault();
//     //     setDownloadCsv(true)
//     // }

//     const getAllQuizReport = (_lmAccountId) => {
//         console.log("ssssssssss", _lmAccountId);
//         axios.post("/quiz/getAllReport", { id: _lmAccountId })
//             .then((res) => {
//                 console.log("REport data:", res.data);
//                 setReportData(res.data)
//             })
//             .catch((err) => {
//                 console.log("Error", err);
//             })
//     }

//     return <Container>
//         <Row>
//             <Row>
//                 <Col>
//                     <SchoolsList setSlctSchool={setSlctSchool} />
//                     <Col className="mt-2 ms-4">
//                         <button className='ms-2 filterBtns' onClick={(e) => {
//                             e.preventDefault();
//                             console.log(("dddddddddddrrrrrrrrrrrrrr", slctSchool));
//                             getAllQuizReport(slctSchool.id)
//                         }}>Select</button>
//                     </Col>
//                 </Col>
//                 <Col className="mt-5" xs={12}>
//                     {
//                         reportData.length > 0 ?
//                             <div>
//                                 {
//                                     reportData.map((quiz, quizId) => {
//                                         console.log("sssssss", quiz);
//                                         return <div className="mt-4">
//                                             <div>
//                                                 <h5>
//                                                     {quiz.quizName}
//                                                 </h5>
//                                                 <div>
//                                                     <button onClick={(e) => { e.preventDefault(); setIsDownloadCSVClicked(true) }}>Download CSV</button>
//                                                     {isDownloadCSVClicked ? <CSVDownload data={quiz.quizUserDetails} target="_blank" /> : ""}
//                                                 </div>
//                                             </div>
//                                             <Table responsive bordered hover>
//                                                 <thead>
//                                                     <tr>
//                                                         <th className="tble-equal-padding" style={{ minWidth: "20px" }}>Id</th>
//                                                         <th className="tble-equal-padding" style={{ minWidth: "150px" }}>Name</th>
//                                                         <th className="tble-equal-padding" style={{ minWidth: "150px" }}>Quiz Result</th>
//                                                     </tr>
//                                                     {
//                                                         quiz.quizUserDetails.map((quizuser, k) => {
//                                                             if (quizuser) {
//                                                                 return <tr>
//                                                                     <td>{k + 1}</td>
//                                                                     <td>{quizuser.userName}</td>
//                                                                     <td>
//                                                                         <thead>
//                                                                             <tr style={{ height: "35px", border: "1px solid #b7b7b7" }}>
//                                                                                 <th className="tble-equal-padding tbl-right-brdr">Attempt</th>
//                                                                                 <th className="tble-equal-padding tbl-right-brdr">Total Questions</th>
//                                                                                 <th className="tble-equal-padding tbl-right-brdr">Questions Attended</th>
//                                                                                 <th className="tble-equal-padding tbl-right-brdr">Correct Answers</th>
//                                                                                 <th className="tble-equal-padding tbl-right-brdr">Score</th>
//                                                                                 <th className="tble-equal-padding tbl-right-brdr">Status</th>
//                                                                             </tr>
//                                                                         </thead>
//                                                                         {
//                                                                             quizuser.quizResultSummary.length > 0 ? quizuser.quizResultSummary.map((quizResult, z) => {
//                                                                                 let count = 0;
//                                                                                 let correctAnswers = quizResult.attemptedQuestions.map((a, b) => {
//                                                                                     if (a.isAnswerCorrect === "YES") {
//                                                                                         count += 1
//                                                                                     }
//                                                                                 })
//                                                                                 return <tr style={{ height: "35px", border: "1px solid #b7b7b7" }}>
//                                                                                     <td className="tble-equal-padding tbl-right-brdr">{z + 1}</td>
//                                                                                     <td className="tble-equal-padding tbl-right-brdr">{quizResult.totalQuestions}</td>
//                                                                                     <td className="tble-equal-padding tbl-right-brdr">{quizResult.attemptedQuestions.length}</td>
//                                                                                     <td className="tble-equal-padding tbl-right-brdr">{count}</td>
//                                                                                     <td className="tble-equal-padding tbl-right-brdr">{quizResult.score}</td>
//                                                                                     <td className={`tble-equal-padding tbl-right-brdr `} style={{ minWidth: "150px" }}>
//                                                                                         <span className={`${quizResult.status === "PASS" ? "passtag" : "failtag"}`}>
//                                                                                             {quizResult.status}
//                                                                                         </span></td>
//                                                                                 </tr>
//                                                                             }) : <div><span style={{ backgroundColor: "red", padding: "0px 5px 0px 5px", color: "white", borderRadius: "50px" }}>Quiz Not Started</span></div>
//                                                                         }
//                                                                     </td>
//                                                                 </tr>
//                                                             }

//                                                         })
//                                                     }
//                                                 </thead>
//                                             </Table>
//                                         </div>
//                                     })
//                                 }
//                             </div> : <h3>No Data found</h3>
//                     }
//                 </Col>
//             </Row>
//         </Row>
//     </Container >

// }


// export default QuizReportNew;