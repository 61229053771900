import { InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import PrimaryTextBox from '../../CmnCompnts/TextBox';
import { useNavigate } from 'react-router-dom';

export default function CreateSubscriptionPage() {
    const [stepsCompleted, setStepsCompleted] = useState(1);
    // const [allPrevSubscriptions, setAllPrevSubscriptions] = useState();
    const [inputField, setInputField] = useState({
        liscenceCategory: "",
        liscenceType: "",
        totalUnits: 0,
        liscencePeriod: "",
        liscenceFrequency: "",
    });

    const [apiResponse, setApiResponse] = useState("")
    const navigate = useNavigate();
    // useEffect(() => {
    //     axios.get("/license/getAllSubscriptions")
    //         .then((res) => {
    //             setAllPrevSubscriptions(res.data)
    //             console.log("Getting all subscriptions", res.data);
    //         })
    //         .catch((err) => {
    //             console.log("Error came while fetching subscriptions");
    //         })
    // }, [])


    const onChangeTxt = (e) => {
        setInputField({
            ...inputField,
            [e.target.name]: e.target.value,
        });
        console.log("ssssssssssss", inputField);
    }

    const onCreateLicense = (e) => {
        e.preventDefault();
        let lmAccountId = localStorage.getItem("lmAccountId")
        let payload = {
            _lmAccountId: lmAccountId,
            category: inputField.liscenceCategory,
            license_type: inputField.liscenceType,
            total_units: inputField.totalUnits,
            period: inputField.liscencePeriod,
            frequency: inputField.liscenceFrequency,
            licenseStartDate: inputField.start_date,
        }
        axios.post("/license/createLicenses", payload)
            .then((res) => {
                setApiResponse("Created Licenses")
                // sendLicenseDetails()
                setTimeout(() => {
                    navigate("/dashboard/assignStudentsToLicense", {
                        state: {
                            LicenseData: res.data
                        }
                    })
                }, 1000);
                console.log("Create license data", res.data);
            })
            .catch((err) => {
                console.log("Create license errorrr", err);
            })
    }

    // const sendLicenseDetails = () => {
    //     axios.get("/license/getAllSubscriptions")
    //         .then((res) => {
    //             setTimeout(() => {
    //                 navigate("/dashboard/assignStudentsToLicense", {
    //                     state: {
    //                         LicenseData: res.data
    //                     }
    //                 })
    //             }, 1000);
    //             console.log("Getting all subscriptions", res.data);
    //         })
    //         .catch((err) => {
    //             console.log("Error came while fetching subscriptions");
    //         })
    // }

    return (
        <>
            <section style={{ maxWidth: "720px", margin: "0px auto", padding: "15px" }}>
                <div>
                    <h3>
                        Create Subscription
                    </h3>
                </div>
            </section>
            <section style={{ maxWidth: "720px", margin: "0px auto", border: "1px solid lightgray", padding: "15px", borderRadius: "10px" }}>
                <Row>
                    <Col xs={12}>
                        <div>
                            <h4><b>Step {stepsCompleted}/2</b></h4>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={"mt-3"}>
                        <div>
                            <label className='w-50' for="liscenceCategory">Choose a Liscence Category</label>

                            <select className='w-50' name="liscenceCategory" id="liscenceCategory" style={{ height: "45px", backgroundColor: "white", border: "1px solid lightgrey", borderRadius: "5px", width: "auto" }} onChange={onChangeTxt}>
                                <option value="select">Select</option>
                                <option value="paid">Paid</option>
                                <option value="free">Free</option>
                            </select>
                        </div>
                    </Col>
                    <Col xs={12} className={"mt-3"}>
                        <div>
                            <label className='w-50' for="liscenceType">Choose a Liscence Type:</label>

                            <select className='w-50' name="liscenceType" id="liscenceType" style={{ minWidth: "100px", height: "45px", backgroundColor: "white", border: "1px solid lightgrey", borderRadius: "5px", width: "auto" }} onChange={onChangeTxt}>
                                <option value="select">Select </option>
                                <option value="Perpetual">Perpetual </option>
                                <option value="Subscription">Subscription </option>
                                <option value="Floating">Floating </option>
                            </select>
                        </div>
                    </Col>
                    <Col xs={12} className={"mt-3"}>
                        <div className='d-flex align-items-center'>
                            <label className='w-50' for="start_date">Start Date</label>
                            <div className='w-50'>
                                <input type='date' name="start_date" style={{ width: "100%", height: "45px", backgroundColor: "white", border: "1px solid lightgrey", borderRadius: "5px", width: "auto" }} onChange={onChangeTxt} />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} className={"mt-3"}>
                        <div className='d-flex align-items-center'>
                            <label className='w-50' for="liscense_type">Total Licenses Units:</label>
                            <div className='w-50'>
                                <PrimaryTextBox name="totalUnits" label="Total Licenses Units" onChange={onChangeTxt} />
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} className={"mt-3"}>
                        <div className='d-flex align-items-center'>
                            <label className='w-50' for="liscencePeriod">Choose a license period:</label>

                            <select className='w-50' name="liscencePeriod" id="liscencePeriod" onChange={onChangeTxt} style={{ width: "100%", height: "45px", backgroundColor: "white", border: "1px solid lightgrey", borderRadius: "5px", width: "auto" }}>
                                <option value="select">Select </option>
                                <option value="Day">Day </option>
                                <option value="Week">Week</option>
                                <option value="Month">Month</option>
                                <option value="Year">Year</option>
                            </select>
                        </div>
                    </Col>

                    <Col xs={12} className={"mt-3"}>
                        <div className='d-flex align-items-center'>
                            <label className='w-50' for="liscenceFrequency">Choose a frequency</label>
                            <div className='w-50'>
                                <PrimaryTextBox name="liscenceFrequency" label="Enter Frequency" onChange={onChangeTxt} />
                            </div>
                        </div>
                        <div className='mt-3'>
                            <h5><b>Liscence Duration:</b> Total {inputField.liscenceFrequency} {inputField.liscencePeriod}</h5>
                        </div>
                    </Col>
                </Row>

                <div className='mt-3' style={{ textAlign: "right" }}>
                    <button style={{ minHeight: "40px", minWidth: "150px", border: "none" }} onClick={onCreateLicense}>
                        Create Licenses
                    </button>
                    <div>
                        {apiResponse}
                    </div>
                </div>
            </section>
        </>
    )
}
