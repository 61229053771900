import React from "react";

const AdminDefaultPage = () => {
  return (
    <section>
      <div style={{ textAlign: "center" }}>
        <h1>HomePage to be designed</h1>
      </div>
    </section>
  );
};
export default AdminDefaultPage;
