import React from 'react'
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useNavigate } from "react-router-dom"

export default function PopUpModel(props) {
    const [show, setShow] = useState(props.show);
    const [message, setMessage] = useState("");
    const navigate = useNavigate()

    const handleClose = () => {
        setShow(false);
        props.cancelClick(false);
    };

    const onYesClick = () => {
        // props.tittle == "submit" ? onSubmitClick() : onCancelClick()
        console.log("props data", props);
        if (props.actionTittle == "Delete") {
            let payload = {
                quizId: props.quizDt.quizId
                // id: mainId,
                // quiz_id: key,
                // quiz_status: status ? "ACTIVATE" : "DEACTIVATE"
            }
            axios.post("/quiz/deleteQuiz", payload)
                .then((res) => {
                    setMessage("Quiz Deleted Successfully!");
                    setTimeout(() => {
                        handleClose();
                        navigate(0)
                    }, 1000);
                    console.log("Quiz Deleted");
                })
                .catch((err) => {
                    console.log("Error", err);
                });
        }
        else {
            let payload = {
                quizId: props.quizDt.quizId,
                quizStatus: props.actionTittle == true ? "Active" : "InActive"
                // id: mainId,
                // quiz_id: key,
                // quiz_status: status ? "ACTIVATE" : "DEACTIVATE"
            }
            axios.post("/quiz/updateQuizStatus", payload)
                .then((res) => {
                    setTimeout(() => {
                        handleClose();
                        props.onChangeData();
                        window.location.reload(); // Reload the page
                    }, 1000);
                    setMessage("Quiz Updated Successfully!");
                }).catch((err) => {
                    console.log("Error", err);
                })
        }
        // navigate("/result");
    }

    const onCancelClick = () => {
        let payload = {
            quizId: props.quizDt.quizId
        };
        axios
            .post("https://account.learningmatters.ai//result", payload)
            .then((res) => {
                console.log("Succesfully cancelled");
            })
            .catch((err) => {
                console.log("Failed to submit");
            });
    }

    useEffect(() => {
        console.log("Calling popup");
        setShow(props.show)
    }, [props.show])

    const onSubmitClick = () => {
        // let tempdt = [];
        // let temp2 = [];
        // let score = 0;
        // let qstnsAttented = [];
        // let status = "intro";
        // let atemptedQstnsData = [];
        // props.data.map((data, key) => {
        //     temp2 = [];
        //     let tempObjdt = [];
        //     let isAnsCrct = "NO";
        //     let userAnswers = [];

        //     if (data.selectedAns) {
        //         let userPickedAnswers = data.selectedAns.map((slctAns, slctKey) => {
        //             userAnswers.push(data.answerOptions[slctAns])
        //         })
        //         if (data.correctAns.length === userAnswers.length) {
        //             let checkAns = data.correctAns.every(elem => userAnswers.includes(elem));
        //             if (checkAns) {
        //                 score += 1;
        //             }
        //             checkAns ? isAnsCrct = "YES" : isAnsCrct = "NO"
        //         }
        //         atemptedQstnsData.push({
        //             qstnId: "Yet to be added",
        //             questionText: data.questionText,
        //             crctAns: data.correctAns,
        //             userAns: userAnswers,
        //             isAnswerCorrect: isAnsCrct,
        //             proficiencyLevel: data.proficiencyLevel,
        //         })
        //     }
        //     if (Object.values(qstnsAttented).length == props.data.length) {
        //         status = "complete"
        //     }
        //     else {
        //         status = "Incomplete"
        //     }
        // });
        // console.log("User attended Quesitons", atemptedQstnsData);

        // let payload = {
        //     attempt: 1,
        //     total_qstns: 30,
        //     atemptedQstnsData: atemptedQstnsData,
        //     score: score,
        //     userId: localStorage.getItem("userId"),
        //     submittedTime: new Date()
        // };
        // axios
        //     .post("http://localhost:5000/quiz/submitResult", payload)
        //     .then((res) => {
        //         console.log("Succesfully submitted");
        //     })
        //     .catch((err) => {
        //         console.log("Failed to submit");
        //     });
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered backdrop="static">
                {/* <Modal.Body>Are you sure you want to cancel?</Modal.Body> */}
                <Modal.Body>
                    <h4>
                        {props.actionTittle == "Delete" ? <div style={{ textAlign: "center" }}>
                            <h4>Are you sure you want to Delete?</h4>
                        </div> : <div style={{ textAlign: "center" }}>
                            <div>
                                <h4>Are you sure you want to {props.actionTittle == true ? "Activate" : "DeActivate"}?</h4>
                            </div>
                        </div>
                        }
                    </h4>
                    <div style={{ textAlign: "center" }}>
                        <h5 style={{ color: "red" }}>{message}</h5>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="cancel-btn" onClick={handleClose}>
                        Cancel
                    </button>
                    <button className="prim-btn" variant="primary" onClick={onYesClick}>
                        {props.actionTittle == "Delete" ? "Delete" : "Update"}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}