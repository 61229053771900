import { Container } from '@mui/material';
import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap';
import SchoolsList from '../../CmnCompnts/SchoolsList';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { style } from '@mui/system';
import { useEffect } from 'react';
import { UserContext } from '../../Context/Reducer';
import { useContext } from 'react';
import { getAllSchoolUsers } from '../../../Services/api';
import axios from 'axios';

const AddStudents = (props) => {
    const [grade, setGrade] = useState("All")
    const [gradeList, setGradeList] = useState([]);
    const [slctSchool, setSlctSchool] = useState();
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [batchStudents, setBatchStudents] = useState([]);
    const [batchStudentList, setBatchStudentList] = useState([])
    const { state, dispatch } = useContext(UserContext) //context dt
    const [batchName, setBatchName] = useState("")
    const [currentBatchList, setCurrentBatchList] = useState([])
    const [apiMessage, setApiMessage] = useState("")
    const [stndsList, setstndsList] = useState([]);
    const [allStudents, setAllStudents] = useState([]);
    const [alreadyExistQuizUserList, setAlreadyExistQuizUserList] = useState([])
    const [showModal, setShowModal] = useState(false);

    const onFilter = () => {
        if (grade === "All") {
            let tempArr = [];
            let tempDt = allStudents.map((dt, i) => {
                if (!currentBatchList.includes(dt._id)) {
                    tempArr.push(dt)
                }
            })
            setstndsList(tempArr)
        }
        else {
            let temp = allStudents.filter((newVal) => {
                if (!currentBatchList.includes(newVal._id)) {
                    return newVal.grade == grade
                }
            })
            setstndsList(temp)
        }
    }

    const onSelectStndtClick = (id) => {
        if (!selectedStudents.includes(id)) {
            setSelectedStudents([...selectedStudents, id])
        }
        else {
            let tempDt = [...selectedStudents];
            const index = tempDt.indexOf(id);
            if (index > -1) {
                console.log("id", id);
                tempDt.splice(index, 1)
                setSelectedStudents(tempDt)
            }
        }
    }

    const onSelectClick = (id) => {
        if (!batchStudentList.includes(id)) {
            setBatchStudentList([...batchStudentList, id])
        }
        else {
            let tempDt = [...batchStudentList];
            const index = tempDt.indexOf(id);
            if (index > -1) {
                console.log("id", id);
                tempDt.splice(index, 1)
                setBatchStudentList(tempDt)
            }
        }
    }

    useEffect(async () => {
        console.log("sssssssssssssssssss", props.accnt_id);
        setstndsList([])
        props.isItForAdmin ? console.log("Loaidng create batch") : await getStudentsAndGrade(props.accnt_id)
        // if (props.action === "Update") {
        //     console.log("Update called", props.batchStudents);
        //     console.log("Update calledsdasdas", stndsList);
        //     // setBatchStudents(props.batchStudents)
        // }
    }, [props.accnt_id])

    const transferData = (tempDt) => {
        let createBatch = [...batchStudents];
        let filterStndList = [];
        let removeSelectedStudents = [...selectedStudents]
        let tempCurrentBatchList = [...currentBatchList]

        stndsList.map((dt, index) => {
            if (selectedStudents.includes(stndsList[index]._id)) {
                createBatch.push(stndsList[index])
                let indexNum = removeSelectedStudents.indexOf(stndsList[index]._id);
                removeSelectedStudents.splice(indexNum, 1)
                tempCurrentBatchList.push(dt._id)
            }
            else {
                filterStndList.push(stndsList[index])
            }
        })
        setCurrentBatchList(tempCurrentBatchList)
        setSelectedStudents(removeSelectedStudents)
        setBatchStudents(createBatch)
        props.setBatchStudents(createBatch)
        setstndsList(filterStndList)
    }

    const transferDataBackward = (tempDt) => {
        let selectedUsers = [...stndsList];
        let filterBtchList = [];
        let removeSelectedStudents = [...batchStudentList]
        let tempCurrentBatchList = [...currentBatchList]

        batchStudents.map((dt, i) => {
            if (batchStudentList.includes(dt._id)) {
                selectedUsers.push(dt)
                let indexNum = removeSelectedStudents.indexOf(batchStudents[i]._id);
                let indexNumForCurntBatch = tempCurrentBatchList.indexOf(batchStudents[i]._id);
                removeSelectedStudents.splice(indexNum, 1)
                tempCurrentBatchList.splice(indexNumForCurntBatch, 1)
            }
            else {
                filterBtchList.push(dt)
            }
        })
        setCurrentBatchList(tempCurrentBatchList)
        setBatchStudentList(removeSelectedStudents)
        setstndsList(selectedUsers)
        setBatchStudents(filterBtchList)
        props.setBatchStudents(filterBtchList)
    }

    const getStudentsAndGrade = () => {
        props.isItForAdmin ? getStudentsForAdmin() : getStudentsForSchoolDashboard()
        // console.log("dddddddddddd", state.selected_School.accnt_id, "=========", props.accnt_id);
        // let data = await getAllSchoolUsers(props.isItForAdmin ? state.selected_School.accnt_id : props.accnt_id);
        // console.log("All students", data);
        // let tempGradeList = []
        // let filter = data.map((dt, i) => {
        //     if (!tempGradeList.includes(dt.grade)) {
        //         tempGradeList.push(dt.grade)
        //     }
        // })
        // console.log("tempGradeList", tempGradeList);

        // setstndsList(data)
        // setAllStudents(data)
        // setGradeList(tempGradeList)
    }

    useEffect(() => {
        console.log("Calling adding students ");
        axios.get('/quiz/getAllQuizBatchUserDetails').then((res) => {
            console.log("getAllQuizBatchUserDetails", res.data.quizBatchUsers);
            setAlreadyExistQuizUserList(res.data.quizBatchUsers)
        })

        // let tempBatch = []
        // tempBatch = state.selected_School.batches;
    }, [])

    const getStudentsForAdmin = async () => {
        // let data = await getAllSchoolUsers(state.selected_School.accnt_id);
        console.log("caddddddddddddddd");

        let data = await getAllSchoolUsers(props.accnt_id);
        console.log("All students", data);
        let tempGradeList = []
        let filter = data.map((dt, i) => {
            if (!tempGradeList.includes(dt.grade)) {
                tempGradeList.push(dt.grade)
            }
        })
        setstndsList(data)
        setAllStudents(data)
        setGradeList(tempGradeList)
    }

    const getStudentsForSchoolDashboard = async () => {
        console.log("props.accnt_id", props.accnt_id);
        let data = await getAllSchoolUsers(props.accnt_id);
        console.log("All students", data);
        if (data.length === 0) {
            setShowModal(true);
            return;
        }
        let tempGradeList = []
        let filter = data.map((dt, i) => {
            if (!tempGradeList.includes(dt.grade)) {
                tempGradeList.push(dt.grade)
            }
        })
        console.log("tempGradeList", tempGradeList);

        if (props.action === "Update") {
            let tempStudentList = []
            let tempBatchList = []
            let batchStudents = data.map((dt, i) => {
                if (!props.batchStudents.includes(dt._id)) {
                    tempStudentList.push(dt)
                }
                else {
                    tempBatchList.push(dt)
                }
            })
            console.log("tempStudentList", tempStudentList);
            console.log("tempBatchList ", tempBatchList);
            setstndsList(tempStudentList)
            setBatchStudents(tempBatchList)
            setAllStudents(data)
            setGradeList(tempGradeList)
            // setBatchStudents(props.batchStudents)
        }
        //For default adding students
        else {
            setstndsList(data)
            setAllStudents(data)
            setGradeList(tempGradeList)
        }
    }

    // useEffect(() => {
    //     props.setAreStudentsAvailable(stndsList.length > 0);
    // }, [stndsList]);

    // const createBatch = (e) => {
    //     e.preventDefault()
    //     if (batchStudents.length <= 0) {
    //         setApiMessage("Please add atleast 1 student")
    //     }
    //     else {
    //         let slctdBatchIds = []
    //         batchStudents.map((dt, i) => {
    //             slctdBatchIds.push({
    //                 id: dt._id,
    //                 name: dt.firstname + " " + dt.lastname,
    //                 result: [
    //                     {
    //                         attempt: 1,
    //                         total_qstns: 30,
    //                         qstns_attended: 25,
    //                         qstns_crct_ans: 10,
    //                         no_of_attempts: 2,
    //                         score: 10
    //                     }
    //                 ]
    //             })
    //         })

    //         let payload = {
    //             id: props.isItForAdmin ? state.selected_School.accnt_id : props.accnt_id,
    //             batch_name: batchName,
    //             batch_students: slctdBatchIds,
    //         }
    //         axios.post("/quiz/createBatch", payload)
    //             .then((res) => {
    //                 console.log("Quiz batch created");
    //                 setApiMessage("Batch Created SFFFuccessfully")
    //             }).catch((err) => {
    //                 console.log("Error in creating batch");
    //             })
    //     }
    // }

    return <div style={{ background: "white", border: "0px solid black" }}>
        {
            stndsList.length > 0 ? <div>
                <Row>
                    <Col xs={12}>
                        <div className='d-flex flex-column filterPanels' >
                            <span>Select Grade</span> <br />
                            <div className='d-flex'>
                                <Dropdown >
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: "white", color: "black", minWidth: "50px", padding: "0px", height: "30px" }}>
                                        {grade}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <Dropdown.Item onClick={() => {
                                            setGrade("All")
                                        }}>All</Dropdown.Item>
                                        {
                                            gradeList.map((dt, i) => {
                                                return <Dropdown.Item onClick={() => {
                                                    setGrade(dt)
                                                }} >{dt}</Dropdown.Item>
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                <button className='ms-2 filterBtns' onClick={(e) => {
                                    e.preventDefault()
                                    onFilter()
                                }}>Filter</button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ minHeight: "350px", paddingBottom: "30px" }}>
                    <Col xs={5}  >
                        <div className={"stdnts-panel mt-3"}>
                            <div className='pt-2' style={{ borderBottom: "1px solid gray" }}>
                                <h5 style={{ textAlign: "center" }}>All Students List</h5>
                            </div>
                            <Row>
                                <Col className='mt-2'>
                                    <div style={{ height: "300px", overflowY: "scroll", padding: "5px" }}>
                                        {console.log("stndsList", stndsList)}
                                        {
                                            stndsList.map((item, id) => {
                                                let txtColor = "black";
                                                if (alreadyExistQuizUserList.includes(item._id) && alreadyExistQuizUserList.length > 0) {
                                                    txtColor = "red";
                                                }
                                                return (
                                                    <div>
                                                        <button className={` ${selectedStudents.includes(item._id) ? "selected" : "studentBtn"} `} onClick={(e) => {
                                                            e.preventDefault();
                                                            onSelectStndtClick(item._id);
                                                        }} value={item._id} style={{ width: "100%", color: `${txtColor}` }}>
                                                            {item.userName}
                                                            {grade === "All" ? <span className='grade-display ms-4'>{item.grade}</span> : ""}
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col xs={2} >
                        <div className={"stdnts-panel mt-3 d-flex flex-column justify-content-center align-items-center"}>
                            <button onClick={transferData} className='arrow-btn '><b> {">>"} </b></button>
                            <button onClick={transferDataBackward} className='arrow-btn mt-2'><b> {"<<"} </b></button>
                        </div>
                    </Col>
                    <Col xs={5} >
                        <div className={"stdnts-panel mt-3"}>
                            <div className='pt-2' style={{ borderBottom: "1px solid gray" }}>
                                <h5 style={{ textAlign: "center" }}>Batch Students</h5>
                            </div>
                            <div >
                                <Row>
                                    <Col xs={12}>
                                        <div style={{ height: "300px", marginTop: "15px", overflowY: "scroll" }}>
                                            {
                                                batchStudents.map((item, id) => {
                                                    return (
                                                        <div style={{ width: "100%" }}>
                                                            <button value={item._id} className={`w-100  ${batchStudentList.includes(item._id) ? "selected" : "studentBtn"}`} onClick={(e) => {
                                                                e.preventDefault();
                                                                onSelectClick(item._id);
                                                            }}>{item.userName}</button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
                :
                <div style={{ color: "red" }}>
                    <b> "No students found for this school" </b>
                </div>
        }
        <div>
            {/* <button className='prim-btn m-2' onClick={createBatch}>Create</button>
            <button className='cancel-btn m-2' onClick={props.onCancelClick}>Cancel</button> */}
            <h5>
                <b>
                    {props.apiMessage}
                </b>
            </h5>
        </div>
    </div>
}

export default AddStudents;


