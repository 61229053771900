import React from "react";
import { Button, Container, Grid, TextField } from "@mui/material";
import NavBar from "../navBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";


function SelectUserType(props) {

    const navigate = useNavigate();

    const onSelectAcntType = (e) => {
        console.log("Account Type:-", e.target.value);
        const payload = {
            userEmail: localStorage.getItem("userEmail"),
            userType: e.target.value
        }

        axios
            .post("/lmAccounts/updateActType", payload)
            .then((res) => {
                navigate("/dashboard/completeProfile")
                console.log("User Account Type Added");
            })
            .catch((error) => console.log(error));
    }

    return (
        <div>
            <Grid container spacing={3} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                <Grid item style={{ marginTop: "30px" }}>
                    <h2>
                        Select User Type
                    </h2>
                </Grid>
                <Grid item >
                    <button value={"BC"} className={"selectBtns"} onClick={onSelectAcntType} style={{ margin: "10px" }}>PERSONAL</button>
                    <button value={"BB"} className={"selectBtns"} onClick={onSelectAcntType} style={{ margin: "10px" }}>School/Institute</button>
                </Grid>
                {/* <Grid item >
                    <button value={"BB"} className={"selectBtns"} onClick={onSelectAcntType}>INSTITUTE</button>
                </Grid> */}
            </Grid>
        </div>
    )
}

export default SelectUserType;