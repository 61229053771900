import { InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { light } from "@mui/material/styles/createPalette";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, DropdownButton, Modal, Row } from "react-bootstrap";
import { getAllQuestionBankSets } from "../../../Services/api";
import DatePicker from "../../CmnCompnts/DatePicker1";
import SchoolsList from "../../CmnCompnts/SchoolsList";
import { UserContext } from "../../Context/Reducer";
import AddStudents from "./AddStudents";
import CreateBatch from "./CreateBatch";

const UpdateQuiz = (props) => {
    const [show, setShow] = useState(props.show);
    const [message, setMessage] = useState("");
    const [validations, setValidations] = useState([]);
    const [inputField, setInputField] = useState({});
    const [batchStudents, setBatchStudents] = useState([]);
    const [QbSet, setQbSet] = useState();
    // const [QbSet, setQbSet] = useState(["QB Set 1", "QB Set 2"]);
    const [showPanel, setShowPanel] = useState(false)

    const handleClose = () => {
        setShow(false);
        props.cancelClick(false);
    };

    useEffect(() => {
        setInputField({
            quizName: props.quizDt.quizName,
            QbSet: props.quizDt.questionBankSetId,
            startDate: props.quizDt.quizStartDate,
            startTime: props.quizDt.quizStartTime,
            endDate: props.quizDt.quizEndDate,
            endTime: props.quizDt.quizEndTime,

        })
        setShow(props.show)
        getQuestionBankData()
    }, [props.show])

    const getQuestionBankData = async () => {
        let data = await getAllQuestionBankSets();
        setQbSet(data)
        console.log("Dataaaaaaaaaaaaaaa", data);
    }

    const onYesClick = () => {
        let isValidationChecked = checkValidation();
        if (isValidationChecked.length <= 0) {
            console.log("Validation completed");
            setMessage("");
            let batchStudentIDs = batchStudents.map((dt, index) => {
                return dt._id
            })

            let payload = {
                quizId: props.quizDt.quizId,
                _lmAccountId: props.accntId,
                quizStatus: "Active",
                quizName: inputField.quizName,
                questionBankSetId: inputField.QbSet,
                quizBatchUsers: batchStudentIDs,
                // startDate: (`${startDate.day}/${startDate.month}/${startDate.year}`),
                startDate: inputField.startDate,
                startTime: inputField.startTime,
                endDate: inputField.endDate,
                endTime: inputField.endTime,
                createdDate: new Date()
            }

            console.log("svvvvvvvvvvvvvvvvv", payload);

            axios.post("/quiz/updateQuiz", payload)
                .then((res) => {
                    console.log("Submitted");
                    setMessage("Quiz Updated Sussefully!")
                    setTimeout(() => {
                        handleClose()
                        window.location.reload()
                    }, 1000);
                })
                .catch((err) => {
                    setMessage("Couldn't Update the quiz, Try Again")
                    setTimeout(() => {
                        handleClose()
                    }, 1000);
                    console.log("Error while creating quiz: ", err);
                })
        }
        else {
            console.log("isValidationChecked", isValidationChecked);
            if (isValidationChecked.includes("dayDifference")) {
                setMessage("end date or time should be after start date or time")
            }
            else {
                setMessage("Please Fill all the fields *")
            }
        }
    }

    const checkValidation = () => {
        console.log("Called 1", inputField);
        let validationCalender = false;
        let requiredValidation = [];

        if (!inputField.quizName) {
            requiredValidation.push("quizName")
        }
        if (!inputField.QbSet) {
            requiredValidation.push("QbSet")
        }
        if (!inputField.startDate) {
            validationCalender = true;
            requiredValidation.push("startDate")
        }
        if (!inputField.startTime) {
            validationCalender = true;
            requiredValidation.push("startTime")
        }
        if (!inputField.endDate) {
            validationCalender = true;
            requiredValidation.push("endDate")
        }
        if (!inputField.endTime) {
            validationCalender = true;
            requiredValidation.push("endTime")
        }
        //comparing two dates
        // let sd = Date.parse(inputField.startDate)
        // let ed = Date.parse(inputField.endDate)
        // let tempDiff = (sd < ed)
        // if (!tempDiff) {
        //     requiredValidation.push("dayDifference");
        // }
        if (!validationCalender) {
            const sDateTime = new Date(`${inputField.startDate} ${inputField.startTime}`); //2023-02-06 03:33
            const eDateTime = new Date(`${inputField.endDate} ${inputField.endTime}`); //2023-02-06 03:33
            let tempDiff = (Date.parse(sDateTime) < Date.parse(eDateTime))
            if (!tempDiff) {
                requiredValidation.push("dayDifference");
            }
            console.log("compare <<<<<<<<<<", sDateTime, "---", eDateTime, "Differe", tempDiff);
        }

        setValidations(requiredValidation)
        return requiredValidation
    }

    const onChangeTxt = (e) => {
        if (e) {
            e.preventDefault()
            setInputField({
                ...inputField,
                [e.target.name]: e.target.value,
            });
        }
        // if (e.target.name === "questionBankSetId") {
        //     setHighlightBtn(e.target.value)
        // }
    };

    const onChangeSearch = (e) => {
        setShowPanel(true);
    }

    const onQBSelect = (qb) => {
        setInputField({
            ...inputField,
            ["QbSet"]: qb,

        });
    }

    return (
        <>
            <Modal show={show} size="lg" onHide={handleClose} centered backdrop="static">
                <Modal.Body>
                    <div>
                        <h5>Edit Quiz </h5>
                    </div>
                    <Row>
                        <Col xs={12}>
                            <TextField className="mt-3" size="medium" name="quizName" defaultValue={props.quizDt.quizName} onChange={onChangeTxt} id="outlined-basic" label="Enter Quiz Name" variant="outlined" style={{ width: "100%" }} error={validations.includes("quizName") ? true : false} />

                        </Col>
                        <Col xs={12}>
                            <div style={{ position: "relative" }}>
                                {console.log("QbSet", props.quizDt)}
                                <h6 className="mt-2"><b>Select Quiz Template</b></h6>
                                {/* <select name="questionBankSetId" style={{ borderTopColor: `${validations.includes("QbSet") ? "red" : "none"}`, width: "100%", marginTop: "5px" }}>
                                    {
                                        QbSet != undefined ? QbSet.map((dt, i) => {
                                            return <option onClick={(e) => {
                                                e.preventDefault();
                                                onQBSelect(dt._id)
                                            }}>
                                                {dt.name}
                                            </option>
                                        }) : "Loading"
                                    }
                                </select> */}
                                {
                                    QbSet !== undefined ? (
                                        <select style={{ backgroundColor: "white", width: "100%", height: "40px", border: "1px solid lightgrey" }} onChange={(e) => onQBSelect(e.target.value)}>
                                            {QbSet.map((dt, i) => {
                                                return <option key={dt._id} value={dt._id} selected={props.quizDt.questionBankSetId === dt._id ? true : false}>
                                                    {dt.name}
                                                </option>
                                            })}
                                        </select>
                                    ) : (
                                        "Loading"
                                    )
                                }

                                {/* <TextField size="medium" name="questionBankSetId" id="outlined-basic" label="Search here to select" variant="outlined"  onChange={onChangeSearch} style={{ width: "100%", marginTop: "5px" }}
                                    error={validations.includes("QbSet") ? true : false}
                                /> */}
                                {/* {showPanel ? (
                                    <div className="w-100 display-panel" style={{ zIndex: "10", backgroundColor: "white", padding: "15px", position: "absolute", left: "0px", maxWidth: "200px", boxShadow: "0px 10px 10px #D0D0D0" }} >
                                        {
                                            QbSet.slice(0, 5).map((dt, key) => {
                                                return (
                                                    <div>
                                                        <span onClick={() => {
                                                            setShowPanel(false);
                                                            onQBSelect(dt)
                                                        }} key={dt} style={{ cursor: "pointer" }} >
                                                            {dt.name}
                                                        </span>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                ) : (
                                    ""
                                )} */}
                            </div>
                            {/* <h6 className="mt-3"><b>Select Quiz Template</b></h6>
                            <TextField className="mt-2" size="medium" name="questionBankSetId" defaultValue={props.quizDt.questionBankSetId} onChange={onChangeTxt} id="outlined-basic" label="Search here to select" variant="outlined" style={{ width: "100%" }} error={validations.includes("quizName") ? true : false} /> */}
                        </Col>
                        <Col xs={12} className="mt-3">
                            <Row>
                                <Col xs={6}>
                                    <div><h6><b>Start Date & Time</b></h6></div>
                                    <div className="d-flex" style={{ width: "100%", marginTop: "5px" }} >
                                        <input type={"date"} name={"startDate"} defaultValue={props.quizDt.quizStartDate} onChange={onChangeTxt} style={{ height: "56px", flex: "1.5", marginRight: "10px", borderRadius: "3px", border: `1px solid ${validations.includes("startDate") ? "red" : "#c4c4c4"}` }} />
                                        <input type={"time"} name={"startTime"} defaultValue={props.quizDt.quizStartTime} onChange={onChangeTxt} style={{ height: "56px", flex: "1", borderRadius: "3px", border: `1px solid ${validations.includes("startTime") ? "red" : "#c4c4c4"}` }} />
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div><h6><b>End Date & Time</b></h6></div>
                                    <div className="d-flex" style={{ width: "100%", marginTop: "5px" }} >
                                        <input type={"date"} name={"endDate"} defaultValue={props.quizDt.quizEndDate} onChange={onChangeTxt} style={{ height: "56px", flex: "1.5", marginRight: "10px", borderRadius: "3px", border: `1px solid ${validations.includes("endDate") ? "red" : "#c4c4c4"}` }} />
                                        <input type={"time"} name={"endTime"} defaultValue={props.quizDt.quizEndTime} onChange={onChangeTxt} style={{ height: "56px", flex: "1", borderRadius: "3px", border: `1px solid ${validations.includes("endTime") ? "red" : "#c4c4c4"}` }} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <AddStudents isItForAdmin={false} accnt_id={props.accntId} setBatchStudents={setBatchStudents} batchStudents={props.quizDt.quizBatchUsers} action={"Update"} />
                        </Col>
                    </Row>

                    {/* <h4>
                        {props.actionTittle == "Delete" ? <div style={{ textAlign: "center" }}>
                            <h4>Are you sure you want to Delete?</h4>
                        </div> : <div style={{ textAlign: "center" }}>
                            <div>
                                <h4>Are you sure you want to {props.actionTittle == true ? "Activate" : "DeActivate"}?</h4>
                            </div>

                        </div>
                        }
                    </h4>
                    <div style={{ textAlign: "center" }}>
                        <h5 style={{ color: "red" }}>{message}</h5>
                    </div> */}
                </Modal.Body>
                <Modal.Footer>
                    <h6 style={{ marginRight: "15px", color: "red" }}><b>{message}</b></h6>
                    <button className="cancel-btn" onClick={handleClose}>
                        Cancel
                    </button>
                    <button className="prim-btn" variant="primary" onClick={onYesClick}>
                        Update
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default UpdateQuiz;
