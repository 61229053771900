import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone"
import "../../CSSFiles/common.css"
import Papa, { parse } from "papaparse";
import { Col, Container, Row } from "react-bootstrap";
import DragDropImg from '../../Assets/dragdrop.svg';

const Dropzone = (props) => {
    const [csvData, SetCsvData] = useState([])
    const [csvFileTxt, SetCsvFileTxt] = useState("Click to select file or drag-and-drop the file here");
    const [csvDataError, setCsvDataError] = useState("")
    const [isDragActive, setIsDragActive] = useState(true);
    const [items, setItems] = useState([
        {
            answerOptions: ["0", "1", "3", "4"],
            crctAns: [0, 2, 3]
        },
        {
            answerOptions: ["0", "1", "3", "4", "5"],
            crctAns: [0, 3]
        }
    ])

    const onDrop = useCallback(acceptedFiles => {
        console.log("Now you can do anything with" +
            " this file as per your requirement", acceptedFiles[0].path)
        SetCsvFileTxt(`Added File Name ${acceptedFiles[0].path}`)
        Papa.parse(acceptedFiles[0], {
            complete: results => {
                { console.log("kkkkk", results.data); }
                let createQuesitonBank = [];
                results.data.map((dt, k) => {
                    if (k != 0) {
                        let tempOptn = [];
                        let qstn = "";
                        let media = ""
                        let imageMedia = "";
                        let crctAns = [];
                        let cefrLvl = "";
                        let optnShfle = ""
                        dt.map((dt2, k2) => {
                            // console.log("dt2", dt2)
                            if (k2 == 1) {
                                qstn = dt2
                            }
                            if (k2 == 2) {
                                cefrLvl = dt2
                            }
                            if (k2 > 2 && k2 < 7) {
                                tempOptn.push(dt2)
                            }
                            if (k2 == 7) {
                                media = dt2
                            }
                            if (k2 == 9) {
                                // if (dt2 == "image") {
                                imageMedia = dt2
                                // }
                            }
                            if (k2 == 10) {
                                optnShfle = dt2
                            }
                            if (k2 == 8) {
                                if (dt2) {
                                    let n = JSON.parse("[" + dt2 + "]");
                                    if (n.length >= 1) {
                                        n.map((p, q) => {
                                            crctAns.push(p - 1)
                                        })
                                    }
                                    else {
                                        crctAns.push(dt2 - 1)
                                    }
                                }
                            }
                        })
                        if (qstn != "") {
                            // console.log("bbbbb", dt);
                            createQuesitonBank.push(
                                {
                                    question: qstn,
                                    answerOptions: tempOptn,
                                    mediaType: imageMedia,
                                    correctAns: crctAns,
                                    mediaPath: media,
                                    profcncyLvl: cefrLvl,
                                    optionShfle: optnShfle,
                                    selectedAns: []
                                }
                            )
                        }
                    }
                })
                SetCsvData(createQuesitonBank)
                props.setCsvData(createQuesitonBank)
                console.log('eeeeeeeeeeeeeeeeee', createQuesitonBank);
                setIsDragActive(false)
            }
        })
    }, [])

    const { getInputProps, getRootProps } = useDropzone({ onDrop })

    const addOptions = (qstnNum, optnNum) => {
        let temp = csvData[qstnNum].correctAns;
        if (!temp.includes(optnNum)) {
            temp.push(optnNum)
        }
        let tempCsvData = [...csvData];
        tempCsvData[qstnNum] = { ...tempCsvData[qstnNum], correctAns: temp }
        console.log("sssssssssss", tempCsvData);
        SetCsvData(tempCsvData)
    }

    const removeOptions = (qstnNum, optnNum) => {
        let temp = csvData[qstnNum].correctAns;
        console.log("rrrrrrrrrrrrrrr", temp);
        if (temp.includes(optnNum)) {
            let indexnum = temp.indexOf(optnNum)
            temp.splice(indexnum, 1)
        }
        console.log("r22222222222222", temp);
        let tempCsvData = [...csvData];
        tempCsvData[qstnNum] = { ...tempCsvData[qstnNum], correctAns: temp }
        console.log("removinggggggggggggg", tempCsvData);
        SetCsvData(tempCsvData)
    }

    const setCsvData = (createQuesitonBank) => {
        setCsvDataError(""); // Clear the error message
        setCsvData(createQuesitonBank);
    }

    return (
        <div style={{ overflowY: "scroll", backgroundColor: "white", maxHeight: "80vh", minheight: "80vh", padding: "5px" }}>
            {
                isDragActive ? <div {...getRootProps()}>
                    <div className="w-100">
                        <input {...getInputProps()} />
                        <div style={{ position: "relative", cursor: "pointer" }}>
                            <img src={DragDropImg} width={"400px"} />
                            <h6 className="dropZoneArea1">
                                <b>Drag files here</b> <br />
                                {csvFileTxt}
                            </h6>
                        </div>
                        {/* <h6 className="dropZoneArea">{csvFileTxt}</h6> */}
                    </div>
                </div> :
                    <div>
                        {/* {
                            csvData.map((dt, k) => {
                                // console.log("Ssssssssss", dt);
                                // return dt
                                if (k != 0) {
                                    return dt.map((dt2, k2) => {
                                        return <div style={{ marginBottom: "20px", textAlign: "start" }}>
                                            {
                                                //To print question
                                                k2 == 1 ? <h5>
                                                    <b> {k}: {dt2}</b>
                                                </h5> : null
                                            }
                                            {
                                                //To print options
                                                k2 >= 3 ? <div>
                                                    <div>
                                                        <input type="radio" id="html" name={`options-${k}`} value="HTML" />
                                                        <label for="html">{dt2}</label>
                                                    </div>
                                                </div> : null
                                            }
                                        </div>
                                    })
                                }
                            })
                        } */}
                        {/* {console.log("csv data", csvData)} */}
                        {
                            csvData.map((dt, k) => {
                                // console.log("dt", dt);
                                return <div className="mt-3" style={{ textAlign: "start" }}>
                                    <h5><b>{k + 1}) {dt.question}</b></h5>
                                    <Row>
                                        <Col md={6}>
                                            <div>
                                                {
                                                    dt.answerOptions.map((optn, optnNum) => {
                                                        return <div>
                                                            <input type="checkbox" id="optns" name={`options-${k}`} value={optn} onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    addOptions(k, optnNum)
                                                                } else {
                                                                    removeOptions(k, optnNum)
                                                                }
                                                            }} defaultChecked={dt.correctAns.includes(optnNum) ? true : false} />
                                                            <label className="ms-2">{optn}</label>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div style={{ textAlign: "end" }}>
                                                <a href={dt.mediaPath}>{dt.mediaPath}</a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            })
                        }
                    </div>
            }
        </div>
    )
}

export default Dropzone;
