import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import taraImg from '../Assets/tara-h.jpeg';
import ToolBoxImg from '../Assets/ToolBox-h.jpg';
import StarTeacherImg from '../Assets/StarTeacher-h.jpeg';
function AllProducts(props) {
    return <section>
        <Row >
            <Col xs={12} className="mb-3 mt-2">
                <span>
                    <h4><b>All Products</b></h4>
                </span>
            </Col>
            <Col xs={12} md={4} xxl={3}>
                <Card style={{ borderRadius: "15px", border: "none", boxShadow: "0px 0px 10px #0000001c" }}>
                    <Card.Img src={taraImg} />
                    <Card.Body>
                        <Card.Title>
                            <b>Tara</b>
                        </Card.Title>
                        Tara teaches international-standard communicative English to children, young adults, and adults.
                        <div className="mt-3 mb-2" style={{ textAlign: "end" }}>
                            <Button style={{ backgroundColor: "white", border: "none", color: "darkgreen", fontWeight: "700" }}>Subscribe</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>

            <Col xs={12} md={4} xxl={3}>
                <Card style={{ borderRadius: "15px", border: "none", boxShadow: "0px 0px 10px #0000001c" }}>
                    <Card.Img src={ToolBoxImg} />

                    <Card.Body>
                        <Card.Title>
                            <b>ToolBox</b>
                        </Card.Title>
                        Specially-designed activity kits for K12. Cater to multiple intelligences while promoting HOTS and STEAM skills.
                        <div className="mt-3 mb-2" style={{ textAlign: "end" }}>
                            <Button style={{ backgroundColor: "white", border: "none", color: "darkgreen", fontWeight: "700" }}>Subscribe</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>

            <Col xs={12} md={4} xxl={3}>
                <Card style={{ borderRadius: "15px", border: "none", boxShadow: "0px 0px 10px #0000001c" }}>
                    <Card.Img src={StarTeacherImg} />

                    <Card.Body>
                        <Card.Title>
                            <b>Star Teacher</b>
                        </Card.Title>
                        Specially-designed activity kits for K12. Cater to multiple intelligences while promoting HOTS and STEAM skills.
                        <div className="mt-3 mb-2" style={{ textAlign: "end" }}>
                            <Button style={{ backgroundColor: "white", border: "none", color: "darkgreen", fontWeight: "700" }}>Subscribe</Button>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </section>
}

export default AllProducts;