import React from 'react';

const data = [
    ['Sl. No.', 'Questions', 'CEFR', 'Option 1', 'Option 2', 'Option 3', 'Option 4', 'Media', 'Correct Answers', 'Media Type'],
];

const DownloadButton = () => {
    const downloadCSV = () => {
        const csvData = data.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'QuestionBankForm.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <button className='prim-btn' onClick={downloadCSV}>
            Download Template
        </button>
    );
};

export default DownloadButton;